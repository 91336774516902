<template>
  <div class="mesa">
    <img src="@/assets/img/icones/menu/icons8_table_top_view_96px.png" />
    <div class="titulo">{{ mesa.mesa }}</div>
    

    <div class="alerta" v-if="mesa.conta_solicitada === 1">
      <b-icon-calculator />      
    </div>
    <div class="alerta alertaEsquerda" v-if="mesa.garcom_solicitado === 1">
      <b-icon-person />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    mesa: Object
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mesas.scss";
</style>